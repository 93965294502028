import { HttpException } from "./HttpException";

export interface UpdateUser {
    email: string;
    account_type?: string;
    enabled?: boolean;
    amount_of_use?: number;
}


export interface User {
    email: string;
    account_type: 'free' | 'premium';
    enabled: boolean;
    amount_of_use: number;
    created_at: string;
}


export const useUser = () => {
    const BASE_URL = `${process.env.REACT_APP_API_URL}/v1/user`;

    const getUserByEmail = async (email: string, accessToken?: string): Promise<User> => {
        const encodedEmail = encodeURIComponent(email);
        const response = await fetch(`${BASE_URL}?email=${encodedEmail}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            throw new HttpException(response.status, response.statusText);
        }
        return await response.json();
    };

    const createUser = async (email: string, accessToken?: string): Promise<User> => {
        const body = {
            email
        };
        const response = await fetch(`${BASE_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(body),
        });
        return await response.json();
    };

    const updateUser = async (userData: UpdateUser, accessToken?: string): Promise<User> => {
        const response = await fetch(`${BASE_URL}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(userData),
        });
        return await response.json();
    };

    return { getUserByEmail, createUser, updateUser };
};

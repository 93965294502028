import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { IScreenshotCropperProps } from '.';
import { useAI } from '../../api/useAI';
import { events } from '../../monitoring/events';
import ResultModal from '../ResultModal/ResultModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createId } from '@paralleldrive/cuid2';
import { useAuth0 } from '@auth0/auth0-react';


const ScreenshotCropper: React.FC<IScreenshotCropperProps> = ({ cropCoords, setCropCoords, setShowSelection }) => {
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [showScreenshotModal, setShowScreenshotModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultText, setResultText] = useState<string>('');
  const screenshotRef = useRef<HTMLDivElement>(null);
  const { generateInsightsByStreams } = useAI();
  const { trackInsightRequestEvent, trackInsightResponseEvent } = events();
  const { user, getAccessTokenSilently } = useAuth0();

  const handleScreenshot = async () => {
    if (!screenshotRef.current || !cropCoords) return;

    const canvas = await html2canvas(document.body, { scale: window.devicePixelRatio });
    const croppedCanvas = document.createElement('canvas');
    const ctx = croppedCanvas.getContext('2d');

    const scale = window.devicePixelRatio;
    const resizeFactor = 1.0;

    croppedCanvas.width = (cropCoords.width - 2) * scale * resizeFactor;
    croppedCanvas.height = (cropCoords.height - 2) * scale * resizeFactor;

    if (ctx) {
      const adjustedX = (cropCoords.x - window.scrollX + 1) * scale;
      const adjustedY = (cropCoords.y - window.scrollY + 1) * scale;

      ctx.drawImage(
        canvas,
        adjustedX,
        adjustedY,
        (cropCoords.width - 2) * scale,
        (cropCoords.height - 2) * scale,
        0,
        0,
        (cropCoords.width - 2) * scale * resizeFactor,
        (cropCoords.height - 2) * scale * resizeFactor,
      );

      const croppedImageData = croppedCanvas.toDataURL();
      setCroppedImage(croppedImageData);

      setShowScreenshotModal(true);
      setShowSelection(false);
    }
  };

  const closeScreenshotModal = () => {
    setShowScreenshotModal(false);
    setCropCoords(null);
  };

  const handleTips = async () => {
    if (!croppedImage) return;

    setLoading(true);
    try {
      const uid = createId();
      trackInsightRequestEvent(uid, croppedImage, user?.email);
      const accessToken = await getAccessTokenSilently();
      await generateInsightsByStreams(croppedImage, accessToken, (chunk) => {
        setResultText((prev) => {
          return prev + chunk
        });
        setShowScreenshotModal(false);
        setLoading(false);
      },
      (result) => {
        trackInsightResponseEvent(uid, croppedImage, result, user?.email);
      });
    } catch (error) {
      console.error('Error generating insights:', error);
      toast.error('An error occurred while generating insights.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <div ref={screenshotRef}>
      <ToastContainer />
      {croppedImage && showScreenshotModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-black bg-opacity-50 absolute inset-0"></div>
          <div className="bg-white rounded-lg shadow-lg p-8 z-10 max-w-2xl w-full relative">
            <button
              onClick={closeScreenshotModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="flex justify-center pt-4">
              <img src={croppedImage} alt="Cropped Screenshot" className="rounded-lg shadow-lg mb-4 max-h-[600px] max-w-full" />
            </div>
            <div className="flex justify-center space-x-4 pt-2">
              <button
                onClick={handleTips}
                className={`px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center space-x-2">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    <span>Processando...</span>
                  </div>
                ) : (
                  'Análise Inteligente ✨'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {resultText.length > 0 && (
        <ResultModal
          croppedImage={croppedImage!}
          markdownText={resultText}
          onClose={() => setResultText('')}
        />
      )}

      <div className="fixed bottom-4 right-4">
        <button
          onClick={handleScreenshot}
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-full shadow-md hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
        >
          Fazer Recorte
        </button>
      </div>
    </div>
  );
};

export default ScreenshotCropper;

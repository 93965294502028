import React, { useState } from 'react';
import FileLoader from '../components/FileLoader/FileLoader';
import PDFViewer from '../components/PDFViewer/PDFViewer';
import Header from '../components/Header/Header';

const Dashboard: React.FC = () => {
  const [filePath, setFilePath] = useState('');

  const handleFileSelect = (path: string) => {
    setFilePath(path);
  };

  return (
    <div>
      <Header />
      <FileLoader onFileSelect={handleFileSelect} />
      <PDFViewer filePath={filePath} />
    </div>
  );
};

export default Dashboard;

import React, { useRef, useEffect } from 'react';
import Header from '../components/Header/Header';
import MobileCameraComponent from '../components/MobileCameraComponent/MobileCameraComponent';

const DashboardMobile: React.FC = () => {
  const cameraContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cameraContainerRef.current) {
      cameraContainerRef.current.scrollTop = cameraContainerRef.current.scrollHeight;
    }
  }, []);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="z-10 relative">
        <Header />
      </div>
      <div
        ref={cameraContainerRef}
        className="flex-grow overflow-auto relative z-0"
      >
        <MobileCameraComponent />
      </div>
    </div>
  );
};

export default DashboardMobile;

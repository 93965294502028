export const useAI = () => {
    const BASE_URL = `${process.env.REACT_APP_API_URL}/v1/ai`;

    const generateInsights = async (image: string, accessToken?: string): Promise<Response> => {
        const body = {
            image
        };
        return await fetch(`${BASE_URL}/insights`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(body)
        });
    };

    const generateInsightsByStreams = async (
        image: string,
        accessToken: string | undefined,
        onChunkReceived: (chunk: string) => void,
        onComplete: (result: string) => void,
    ): Promise<void> => {
        const body = JSON.stringify({ image });


        const response = await fetch(`${BASE_URL}/insights/stream`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch insights');
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");
        let result = '';

        if (reader) {
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value, { stream: true });
                result += chunk;
                onChunkReceived(chunk);
            }
        }
        onComplete(result);
    };

    return { generateInsights, generateInsightsByStreams };
};

import React from 'react';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';

interface SlideUpCardProps {
  isVisible: boolean;
  onClose: () => void;
  resultText: string;
}

const SlideUpCard: React.FC<SlideUpCardProps> = ({ isVisible, onClose, resultText }) => {
  return (
    <div
      className={`fixed bottom-0 left-0 w-full h-[85%] bg-white rounded-t-3xl shadow-lg transform transition-transform duration-300 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className="flex justify-between p-4">
        <h2 className="text-xl font-bold">Resultado da Análise</h2>
        <button
          onClick={onClose}
          className="text-gray-600 font-semibold"
        >
          Fechar
        </button>
      </div>
      <div className="p-4 pt-2 pb-8 overflow-y-auto h-[calc(100%-64px)]">
        <Markdown className="text-justify space-y-4" remarkPlugins={[remarkGfm]}>
          {resultText}
        </Markdown>
      </div>
    </div>
  );
};

export default SlideUpCard;

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { IAuthProviderProps } from '.';

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{ 
        redirect_uri: window.location.origin, 
        cacheLocation: 'localstorage',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

import mixpanel from "mixpanel-browser";


export const events = () => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN || '';

    const init = () => {
        mixpanel.init(token, {
            debug: true,
            track_pageview: true,
            persistence: 'localStorage',
        });
    };

    const trackInsightRequestEvent = (id: string, image: string, email?: string) => {
        const data = {
            id, 
            email,
            image,
        }
        mixpanel.track('Insight Request', data);
    }

    const trackInsightResponseEvent = (id: string, image: string, result: string, email?: string) => {
        const data = {
            id,
            email,
            image,
            result,
        }
        mixpanel.track('Insight Response', data);
    }

    const trackFileUploadEvent = (filename: string, email?: string) => {
        const data = {
            email,
            filename,
        }
        mixpanel.track('File Uploaded', data);

    }

    return { 
        init,
        trackInsightRequestEvent,
        trackInsightResponseEvent,
        trackFileUploadEvent 
    };
}

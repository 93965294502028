import React, { useEffect, useState } from 'react';
import './App.css';
import Dashboard from './pages/Dashboard';
import DashboardMobile from './pages/DashboardMobile';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './pages/Login';
import { events } from './monitoring/events';
import { useUser, User } from './api/useUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HttpException } from './api/HttpException';
import { isMobile } from 'react-device-detect';

const App: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { getUserByEmail, createUser } = useUser();
  const [isUserEnabled, setIsUserEnabled] = useState<boolean | null>(null);
  const [errorShown, setErrorShown] = useState<boolean>(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (isAuthenticated && user?.email) {
        try {
          events().init();
          const accessToken = await getAccessTokenSilently();

          let existingUser: User | null = null;
          try {
            existingUser = await getUserByEmail(user.email, accessToken);
          } catch (error) {
            if (error instanceof HttpException && error.statusCode === 404) {
              existingUser = await createUser(user.email, accessToken);
            } else {
              throw error;
            }
          }

          if (existingUser?.enabled) {
            setIsUserEnabled(true);
          } else {
            setIsUserEnabled(false);
            if (!errorShown) {
              toast.error('Sua conta não está habilitada. Por favor, entre em contato com o suporte.');
              setErrorShown(true);
            }
          }

        } catch (error) {
          if (!errorShown) {
            toast.error('Ocorreu um erro ao verificar o status do usuário.');
            setErrorShown(true);
          }
        }
      }
    };

    checkUserStatus();
  }, [isAuthenticated, user, getAccessTokenSilently, getUserByEmail, createUser, errorShown]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer />
      {isAuthenticated && isUserEnabled ? (isMobile ? <DashboardMobile /> : <Dashboard />) : <Login />}
    </div>
  );
};

export default App;

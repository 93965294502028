import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IResultModalProps } from '.';

const ResultModal: React.FC<IResultModalProps> = ({ croppedImage, markdownText, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-50 absolute inset-0"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-[70%] h-[90%] flex relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div className="w-1/2 p-4 flex flex-col">
          <div className="text-xl font-semibold mb-4 border-b pb-2">Questão</div>
          <div className="flex-grow overflow-auto">
            <div className="flex justify-center items-center">
              <img src={croppedImage} alt="Cropped Screenshot" className="rounded-lg shadow-lg max-h-full max-w-full" />
            </div>
          </div>
        </div>

        <div className="w-1/2 p-4 flex flex-col">
          <div className="text-xl font-semibold mb-4 border-b pb-2">Resultado</div>
          <div className="flex-grow overflow-auto p-2 border rounded-lg">
            <Markdown className="text-justify space-y-4" remarkPlugins={[remarkGfm]}>{markdownText}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultModal;

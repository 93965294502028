import React, { useState } from 'react';
import { IFileLoaderProps } from '.';
import { events } from '../../monitoring/events';
import { useAuth0 } from '@auth0/auth0-react';


const FileLoader: React.FC<IFileLoaderProps> = ({ onFileSelect }) => {
  const [filePath, setFilePath] = useState('');
  const { trackFileUploadEvent } = events();
  const { user } = useAuth0();

  // const handleLoad = () => {
  //   if (filePath) {
  //     onFileSelect(filePath);
  //   }
  // };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {  
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);
      trackFileUploadEvent(file.name, user?.email);

      setFilePath(fileURL);
      onFileSelect(fileURL);
    }
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="flex space-x-2">
        <input
          type="text"
          value={filePath}
          onChange={(e) => setFilePath(e.target.value)}
          placeholder="Digite o caminho do arquivo PDF a ser carregado"
          className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 w-[600px]"
        />
        {/* <button
          onClick={handleLoad}
          className="px-4 py-2 w-36 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          URL
        </button> */}
        <label className="px-4 py-2 w-36 text-center bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 cursor-pointer">
          Arquivo Local
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileUpload}
            className="hidden"
          />
        </label>
      </div>
    </div>
  );
};

export default FileLoader;

import { useState } from 'react';
import { IHeaderProps } from '.';
import { useAuth0 } from '@auth0/auth0-react';
import { FiMoreVertical } from 'react-icons/fi';
import logo from '../../assets/logo.png';

const Header: React.FC<IHeaderProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useAuth0();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 p-4 flex justify-between items-center w-full">
      <div className="flex items-center">
        <div className="bg-white rounded-full p-1.5 flex items-center justify-center">
          <img src={logo} alt="Logo" className="w-10 h-10" />
        </div>
        <h1 className="text-white ml-4 text-lg sm:text-xl">Prova Simples</h1>
      </div>
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="text-white px-2 py-2 rounded-md focus:outline-none"
        >
          <FiMoreVertical size={24} />
        </button>
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
            <button
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              Sair
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

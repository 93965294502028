import React, { useRef, useState, useEffect } from 'react';
import { FiImage, FiRefreshCcw } from 'react-icons/fi';
import SlideUpCard from '../SlideUpCard/SlideUpCard';
import { useAuth0 } from '@auth0/auth0-react';
import { useAI } from '../../api/useAI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createId } from '@paralleldrive/cuid2';
import { events } from '../../monitoring/events';

const MobileCameraComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isCaptured, setIsCaptured] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [resultText, setResultText] = useState('');
  const [insightFetched, setInsightFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedImage, setLoadedImage] = useState<string | null>(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const { generateInsightsByStreams } = useAI();
  const { trackInsightRequestEvent, trackInsightResponseEvent } = events();

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: isFrontCamera ? 'user' : 'environment' },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        toast.error('Erro ao acessar a câmera.');
      }
    };

    getMediaStream();
  }, [isFrontCamera]);

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        setIsCaptured(true);
        setLoadedImage(null);
        setInsightFetched(false);
        setResultText('');
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLoadedImage(reader.result as string);
        setIsCaptured(true);
        setInsightFetched(false);
        setResultText('');
      };
      reader.readAsDataURL(file);
    }
  };

  const swapCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  const handleAnalysisClick = async () => {
    if (!insightFetched) {
      const croppedImage = loadedImage || canvasRef.current?.toDataURL();
      if (croppedImage) {
        const uid = createId();
        trackInsightRequestEvent(uid, croppedImage, user?.email);  
        const accessToken = await getAccessTokenSilently();
        try {
          setIsLoading(true);
          await generateInsightsByStreams(
            croppedImage,
            accessToken,
            (chunk) => {
              setResultText((prev) => prev + chunk);
              setIsCardVisible(true);
              setIsLoading(false);
            },
            (result) => {
              setResultText(result);
              trackInsightResponseEvent(uid, croppedImage, result, user?.email);
            }
          );
          setInsightFetched(true);
        } catch (error) {
          toast.error('Erro ao processar a análise.');
          setIsLoading(false);
        }
      } else {
        toast.error('Imagem inválida.');
      }
    }
    setIsCardVisible(true);
  };

  const handleResetClick = async () => {
    setIsCaptured(false);
    setResultText('');
    setInsightFetched(false);
    setLoadedImage(null);
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: isFrontCamera ? 'user' : 'environment' },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      toast.error('Erro ao reinicializar a câmera.');
    }
  };

  const handleCloseCard = () => {
    setIsCardVisible(false);
  };

  return (
    <div className="relative w-full h-[calc(100vh-84px)] bg-gray-900">
      {loadedImage ? (
        <img src={loadedImage} alt="Imagem carregada" className="absolute top-0 left-0 w-full h-full object-contain" />
      ) : (
        <>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className={`absolute top-0 left-0 w-full h-full object-cover ${isCaptured ? 'hidden' : 'block'}`}
          />
          <canvas
            ref={canvasRef}
            className={`absolute top-0 left-0 w-full h-full object-cover ${isCaptured ? 'block' : 'hidden'}`}
          />
        </>
      )}

      <div className="absolute bottom-[0vh] left-0 w-full h-24 bg-black bg-opacity-50 flex items-center justify-around">
        {isCaptured ? (
          <>
            <button
              onClick={handleAnalysisClick}
              className="bg-blue-500 text-white text-xs w-40 h-10 rounded-md focus:outline-none flex items-center justify-center ml-4"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Processando...
                  <div className="ml-2 border-t-2 border-r-2 border-white rounded-full w-4 h-4 animate-spin"></div>
                </>
              ) : (
                'Análise Inteligente ✨'
              )}
            </button>
            <button
              onClick={handleResetClick}
              className="bg-gray-500 text-white text-xs w-40 h-10 rounded-md focus:outline-none flex items-center justify-center mr-4"
              disabled={isLoading}
            >
              Capturar Nova Questão
            </button>
          </>
        ) : (
          <>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              className="hidden"
              id="upload"
            />
            <label htmlFor="upload" className="p-2 rounded-lg flex items-center justify-center focus:outline-none bg-transparent cursor-pointer">
              <FiImage size={30} className="text-white opacity-80" />
            </label>

            <button
              onClick={capturePhoto}
              className="p-4 bg-transparent focus:outline-none"
            >
              <div className="relative w-16 h-16 bg-gray-700 bg-opacity-80 rounded-full flex items-center justify-center">
                <div className="w-12 h-12 bg-white rounded-full"></div>
              </div>
            </button>

            <button
              onClick={swapCamera}
              className="p-2 rounded-full flex items-center justify-center focus:outline-none bg-transparent"
            >
              <FiRefreshCcw size={30} className="text-white opacity-80" />
            </button>
          </>
        )}
      </div>

      <SlideUpCard 
        isVisible={isCardVisible} 
        onClose={handleCloseCard} 
        resultText={resultText} 
      />
      
      <ToastContainer />
    </div>
  );
};

export default MobileCameraComponent;

import React, { useState } from 'react';
import { 
  Root, 
  Viewport, 
  Pages, 
  Page, 
  CanvasLayer, 
  CurrentPage, 
} from '@fileforge/pdfreader';
import { IPDFViewerProps } from '.';
import Cropper from '../Cropper/Cropper';
import ScreenshotCropper from '../ScreenshotCropper/ScreenshotCropper';

const PDFViewer: React.FC<IPDFViewerProps> = ({ filePath }) => {
  const [cropCoords, setCropCoords] = useState<{ x: number; y: number; width: number; height: number } | null>(null);
  const [showSelection, setShowSelection] = useState(true);
  
  const handleCropComplete = (coords: { x: number; y: number; width: number; height: number }) => {
    setCropCoords(coords);
  };

  return (
    <div className='flex flex-col items-center pt-10'>
      {filePath && (
        <Root
          className='bg-gray-30 border rounded-md overflow-hidden relative h-[500px] w-[800px]'
          fileURL={filePath}
          loader={
            <div className='p-4 flex justify-center items-center h-full'>
              <svg
                className='animate-spin h-10 w-10 text-gray-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                ></path>
              </svg>
            </div>
          }
        >
          <div className='bg-gray-100 border-b p-1 flex items-center justify-center text-sm text-gray-600 gap-2'>
            <span className='flex-grow' />
            Página
            <CurrentPage className='bg-white rounded-full px-3 py-1 border text-center' />
            <span className='flex-grow' />
          </div>

          <Viewport className='p-4 h-full'>
            <Pages>
              <Page className='my-4'>
                <CanvasLayer />
              </Page>
            </Pages>

            <Cropper 
                onCropComplete={handleCropComplete}
                showSelection={showSelection}
                setShowSelection={setShowSelection}
            />
          </Viewport>
        </Root>
      )}

      {cropCoords && (
        <div className='mt-4'>
          <ScreenshotCropper 
            cropCoords={cropCoords} 
            setCropCoords={setCropCoords} 
            setShowSelection={setShowSelection} 
        />
        </div>
      )}
    </div>
  );
};

export default PDFViewer;

import React, { useState } from 'react';
import { ICropperProps } from '.';

const Cropper: React.FC<ICropperProps> = ({ onCropComplete, showSelection, setShowSelection }) => {
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [selection, setSelection] = useState<{ x: number; y: number; width: number; height: number } | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setStartPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!startPoint) return;
    setShowSelection(true);

    const rect = e.currentTarget.getBoundingClientRect();
    const endPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
    const selectionCoords = {
      x: Math.min(startPoint.x, endPoint.x),
      y: Math.min(startPoint.y, endPoint.y),
      width: Math.abs(startPoint.x - endPoint.x),
      height: Math.abs(startPoint.y - endPoint.y),
    };

    setSelection(selectionCoords);
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (selection) {
      const rect = e.currentTarget.getBoundingClientRect();

      const absoluteCoords = {
        x: rect.left + window.scrollX + selection.x,
        y: rect.top + window.scrollY + selection.y,
        width: selection.width,
        height: selection.height,
      };

      onCropComplete(absoluteCoords);
    }
    setStartPoint(null);
  };

  return (
    <div
      className="absolute inset-0"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ cursor: 'crosshair' }}
    >
      {selection && showSelection && (
        <div
          className="absolute border border-blue-500"
          style={{
            left: selection.x,
            top: selection.y,
            width: selection.width,
            height: selection.height,
            transition: 'none',
          }}
        />
      )}
    </div>
  );
};

export default Cropper;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../assets/logo.png';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-3xl font-bold text-center mb-4">Bem-vindo</h2>
        <img src={logo} alt="Logo" className="mx-auto w-48 h-auto" />
        <p className="text-sm text-center text-gray-500 mb-6">
          Faça login para continuar
        </p>

        <button
          onClick={() => loginWithRedirect({ authorizationParams: { connection: 'google-oauth2' } })}
          className="w-full bg-white border border-gray-300 text-gray-700 p-3 rounded-md flex items-center justify-center space-x-2 hover:bg-gray-100"
        >
          <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
          <span>Continuar com Google</span>
        </button>

        <button
          onClick={() => loginWithRedirect({ authorizationParams: { connection: 'facebook' } })}
          className="mt-4 w-full bg-white border border-gray-300 text-gray-700 p-3 rounded-md flex items-center justify-center space-x-2 hover:bg-gray-100"
        >
          <img src="https://www.facebook.com/favicon.ico" alt="Facebook" className="w-5 h-5" />
          <span>Continuar com Facebook</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
